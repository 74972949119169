import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Lazy load the components
const Welcome = React.lazy(() => import('./components/onboarding/Welcome'));
const MobileVerification = React.lazy(() => import('./components/onboarding/MobileVerification'));
const EmailOTPVerification = React.lazy(() => import('./components/onboarding/EmailOTPVerification'));
const ReferralCodeVerification = React.lazy(() => import('./components/onboarding/ReferralCodeVerification'));
const AstrologerProfileForm = React.lazy(() => import('./components/onboarding/AstrologerProfile')); 
const Dashboard = React.lazy(() => import('./components/dashboard/Dashboard')); // Existing import for Dashboard
const EarningsDashboard = React.lazy(() => import('./components/MoneySection/EarningsDashboard')); // New import for EarningsDashboard
const Transactions = React.lazy(()=> import ('./components/MoneySection/TransactionTable'))
const Withdrawal = React.lazy(()=> import ('./components/MoneySection/WithdrawalForm'))
const EditProfile = React.lazy(()=>  import('./components/MyProfile/EditProfile'))
const EditBankDetails = React.lazy(()=> import('./components/MoneySection/EditBankDetails'))
const SessionHistory = React.lazy(()=> import('./components/MyProfile/SessionHistory'))
const Availability = React.lazy(()=> import('./components/MyProfile/Availability'))
const ContactUs = React.lazy(()=> import('./components/MyProfile/ContactUs'))
const Onboarding = React.lazy(()=> import('./components/onboarding/Onboarding'))
const AstrologerChat = React.lazy(()=> import('./components/LiveChat/AstrologerChat'))
const Footer =React.lazy(()=> import('./components/Footer'))
const PrivacyPolicy=React.lazy(()=> import('./components/FooterFiles/PrivacyPolicy'))
const TermsAndConditions=React.lazy(()=> import('./components/FooterFiles/TermsAndConditions'))

const CancellationRefundPolicy=React.lazy(()=> import('./components/FooterFiles/CancellationPolicy'))


function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/verify-mobile" element={<MobileVerification />} />
          <Route path="/verify-email" element={<EmailOTPVerification />} />
          <Route path="/verify-referral" element={<ReferralCodeVerification />} />
          <Route path="/astrologer-profile" element={<AstrologerProfileForm />} />
          <Route path="/home" element={<Dashboard />} />
          <Route path="/earnings" element={<EarningsDashboard />} /> 
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/withdrawal" element={<Withdrawal />} /> 
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/bank-details" element={<EditBankDetails />} />
          <Route path="/session-history" element={<SessionHistory />} />
          <Route path="/set-availability" element={<Availability />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/astrologerChat/:astroId/:userId" element={<AstrologerChat />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy />} />
        </Routes>
      </Suspense>
      <Footer/>
    </Router>
  );
}

export default App;
